#course_card_title {
    left: 45px;
    position: relative;
    background-color: rgb(255, 149, 0, 0.914);
    border-radius: 0px 20px 35px 0px;
    padding: 0px 0px 0px 16px;

}

#title_btn_design{
    background: rgb(12, 111, 231);
    padding: 20px;
    border-radius: 0px 5px 35px 0px;
}
@media screen and(max-width:620px) {
    #course_card_title {
        left: 0px;
        position: relative;
        background-color: rgb(255, 149, 0, 0.914);
        border-radius: 0px 0px 35px 0px;
        padding: 0px 0px 0px 16px;
    
    }
}