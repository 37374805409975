#header {
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.771);
}
.navLogo {
  filter: drop-shadow(1px 0px 15px white);
}

.landing-background{
  background-image: url("./assets/Background.jpg");
  background-size: "cover";
  background-position: "center";
}

.glowEffect:hover {
  border-color: rgba(255, 149, 0, 0.914);
  border-width: 1px;
  scale: 1.09;
  filter: drop-shadow(0px 0px 5px rgb(247, 202, 67));
  color: white;
}

.active {
  background-color: rgba(255, 149, 0, 0.914);
  filter: drop-shadow(0px 0px 5px rgb(247, 202, 67));
  color: white;
  border-radius: 500px;
  padding: 3px 10px;
}
.mobile-nav-items{
  border-radius: 10px;
  padding: 1rem 0.8rem;
  font-size: larger;
  text-transform: uppercase;
  width: 90%;
  border: 1px solid rgba(128, 128, 128, 0.646);
  color: white;
}
.mobile-nav-items:hover{
  background-color: rgba(67, 70, 73, 0.742);
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 16px !important;
  height: 4px !important;
  border-radius: 5px !important;
  margin: 0 6px !important;
}

.swiper-pagination {
  bottom: 2px !important;
}

.swiper-wrapper {
  height: max-content !important;
  width: max-content !important;
  padding-bottom: 64px;
}

.swiper-pagination-bullet-active {
  background: #4F46E5 !important;
}

.swiper-slide.swiper-slide-active>.slide_active\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.swiper-slide.swiper-slide-active>.group .slide_active\:text-gray-800 {
  ---tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
#sidebar__container {
  transition: 770ms cubic-bezier(0.68, -1.55, 0.27, 1.55);
}
.close-menu{
  width: 0;
}
.contact-bg{
  background: url("./assets//icon//backround.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.contact-title::after{
  content: "";
  height: 3px;
  width: 10rem;
  display: flex;
  background-color: orange;
  border-radius: 50px;
  position: relative;
  top: 2px;
}
.brightness-low{
  backdrop-filter: brightness(0.5)
}
.bg-live{
  background: url("./assets//icon/live.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter:drop-shadow(2px 2px 10px black);
  animation: tilt-leftRight 2s linear infinite 0s alternate;
}

@media screen and (max-width: 820px) {
  #landing_background {
    background-position: center;
  }
  .landing-background{
    background: black;
  }
}
