.hexagon {
    width: 100px;
    height: 55px;
    background-color: rgb(33 23 23 / 10%);
    position: relative;
    margin: 27.5px 0;
    border-left-width: 2PX;
    border-right-width: 2px;
    border-color: rgb(255, 149, 0, 0.914);
    filter: drop-shadow(2px 2px 6px rgb(247, 202, 67));
}


.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 27.5px solid rgba(33, 23, 23, 10%);
  
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 27.5px solid rgba(33, 23, 23, 10%);
}

.hexagon-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.hexagon-text {
  margin: 22px 0px;
  text-align: center;
  font-weight: 500;
  color: white;
font-size: 1.2rem;
}
