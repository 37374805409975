@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Cinzel:wght@400..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Courgette&family=Gruppo&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Syne:wght@400..800&display=swap");
.cinzel{
  font-family:"Cinzel", serif ;
}
.text-glow{
  filter: drop-shadow(1px 1px 15px rgb(32, 3, 255));
}
.courgette{
  font-family: "Courgette", cursive;
}
.charmonman{
  font-family: "Jost", sans-serif;
}
.cabin{
  font-family:"Cabin", sans-serif;
}
.gruppo{
  font-family:"Gruppo", sans-serif;
}
.section_headings {
  font-family: "syne", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
#App{
  overflow-x: hidden;
  width: 100vw;
}
.bgCourse{
  background: url("./assets//icon/courseLandingBG.jpg");
  background-size: cover;
}